import { Typography } from "@mui/material";
import { green } from "@mui/material/colors";

type SuccessMessageProps = {
  message: string;
};
export const SuccessMessage = ({ message }: SuccessMessageProps) => (
  <Typography sx={{ color: green[800], textAlign: "center", fontWeight: 800 }}>
    {message}
  </Typography>
);
